import { Injectable, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PermissionNames } from '../entities/permission';
import { PermissionsService } from './permissions.service';

export type HeaderMenuItem = MenuItem & {
  pathMatch?: 'partial' | 'full';
  permissions?: PermissionNames[];
  items?: HeaderMenuItem[];
  keepNested?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class MenuAccessService {
  private permissionsService = inject(PermissionsService);

  private menuItems: HeaderMenuItem[] = [];
  private allowedMenuItems: HeaderMenuItem[] = [];

  setMenuItems(items: HeaderMenuItem[]) {
    this.menuItems = items;
    this.allowedMenuItems = this.menuItems.map(this.filterItems.bind(this)).filter((item): item is HeaderMenuItem => item !== false);
  }

  getAllowedItems() {
    return this.allowedMenuItems;
  }

  checkMenuRouteAccess(path: string): boolean {
    return !!this.searchByRoutePath(path, this.allowedMenuItems);
  }

  private trimSlashes(value: string) {
    return value.replace(/^\/+|\/+$/g, '');
  }

  private searchByRoutePath(routePath: string, items: HeaderMenuItem[]): HeaderMenuItem | undefined {
    const path = this.trimSlashes(routePath);
    return items.find(item => {
      const route = item.routerLink?.join('/') ?? '';
      const match = item.pathMatch === 'partial' ? route.indexOf(path) > -1 : path.split('/').some(v => route.indexOf(v) > -1);
      return match || (item.items && this.searchByRoutePath(path, item.items));
    });
  }

  private filterItems(item: HeaderMenuItem): HeaderMenuItem | false {
    if (!this.verifyAccess(item)) {
      return false;
    }
    return { ...item, items: item.items?.map(this.filterItems.bind(this)).filter(Boolean) as HeaderMenuItem[] };
  }

  private verifyAccess(item: HeaderMenuItem) {
    return !item.permissions || this.permissionsService.hasPermission(item.permissions);
  }
}
