export interface Permission {
  id: number;
  permission: string;
  roles: number[];
  description: string;
}

export enum Roles {
  'role_admin' = 'role_admin',
  'role_OfficeManager' = 'role_OfficeManager',
  'role_Underwriter' = 'role_Underwriter',
  'role_dataentry' = 'role_dataentry',
  'role_UnderwriterLimited' = 'role_UnderwriterLimited',
  'role_collection' = 'role_collection',
  'role_accounting' = 'role_accounting',
  'role_iso_relations' = 'role_iso_relations',
  'role_accounting_extern' = 'role_accounting_extern',
  'role_iso' = 'role_iso',
  'role_commission' = 'role_commission',
  'role_ins_commission' = 'role_ins_commission',
  'role_contract_fee' = 'role_contract_fee',
  'role_Lender' = 'role_Lender',
  'role_collateral_provider' = 'role_collateral_provider',
  'role_mcawfdashboard' = 'role_mcawfdashboard',
  'role_mca_user' = 'role_mca_user',
  'role_manager' = 'role_manager',
  'role_FUT_INC_PREPARER' = 'role_FUT_INC_PREPARER',
  'role_FUT_INC_MAKER' = 'role_FUT_INC_MAKER',
  'role_FUT_INC_CHECKER' = 'role_FUT_INC_CHECKER',
  'role_investor_fee' = 'role_investor_fee',
  'role_monthly_admin_fee' = 'role_monthly_admin_fee',
  'role_wire_fee' = 'role_wire_fee',
  'role_ucc_fee' = 'role_ucc_fee',
  'role_stacking_fee' = 'role_stacking_fee',
  'role_nsf_fee' = 'role_nsf_fee',
  'role_default_fee' = 'role_default_fee',
  'role_extern_cpa' = 'role_extern_cpa',
  'role_ext_lawyer' = 'role_ext_lawyer',
  'role_ext_debt_stlmt' = 'role_ext_debt_stlmt',
  'role_email_mgmt' = 'role_email_mgmt',
  'role_uw_calc' = 'role_uw_calc',
  'datamerch_r' = 'datamerch_r',
  'datamerch_w' = 'datamerch_w',
  'role_blockedpayment_fee' = 'role_blockedpayment_fee',
  'role_deactivated_iso' = 'role_deactivated_iso',
  'role_deactivated_iso_relations' = 'role_deactivated_iso_relations',
  'role_ext_collection_agent' = 'role_ext_collection_agent',
  'role_loan_issuer' = 'role_loan_issuer',
  'role_account_receivable' = 'role_account_receivable',
}

export type RoleNames = keyof typeof Roles;

export type PermissionNames =
  | 'refdata_w'
  | 'import_w'
  | 'mca_status_w'
  | 'mca_dba_w'
  | 'mca_doc_w'
  | 'loan_doc_w'
  | 'mca_funding_w'
  | 'mca_funding_r'
  | 'mca_lenders_w'
  | 'mca_payrol_w'
  | 'mca_payment_w'
  | 'mca_notes_w'
  | 'mca_contacts_w'
  | 'mca_workflow_w'
  | 'mca_filters_iso'
  | 'refdata_r'
  | 'import_r'
  | 'mca_status_r'
  | 'mca_dba_r'
  | 'mca_doc_r'
  | 'mca_finance_r'
  | 'mca_finance_w'
  | 'mca_lenders_r'
  | 'mca_payrol_r'
  | 'mca_payment_r'
  | 'mca_notes_r'
  | 'mca_workflow_r'
  | 'mca_events_w'
  | 'mca_events_r'
  | 'mca_transactions_r'
  | 'mca_performance_r'
  | 'mca_assignment_r'
  | 'mca_assignment_w'
  | 'roles_iso'
  | 'roles_commission'
  | 'datamerch_r'
  | 'datamerch_w'
  | 'mca_status_cashadvance_full_view'
  | 'mca_balances_tab'
  | 'mca_deal_tab'
  | 'live_messages_edit'
  | 'merchant_contract_submit'
  | 'mca_changerate_w'
  | 'mca_changestatus_w'
  | 'mca_payment_status_w'
  | 'mca_cashadvanse_w'
  | 'menu_pm_defaults'
  | 'mca_add_owner'
  | 'mca_add_iso'
  | 'accounting_w'
  | 'offer_underwriting_w'
  | 'offer_contract_request'
  | 'offer_underwriting_form'
  | 'offer_underwriting_form_iso'
  | 'workload_iso_offers'
  | 'workload_iso_offer_requests'
  | 'workload_iso_offer_responses'
  | 'mca_transactions_w'
  | 'mca_transactions_final_stages_w'
  | 'mca_transactions_user'
  | 'mca_payment_status_r'
  | 'mca_collateral_portfolio_w'
  | 'mca_collateral_portfolio_r'
  | 'mca_send_decline_email'
  | 'mca_side_notes_r'
  | 'mca_status_renewals'
  | 'mca_notes_tab_notes'
  | 'mca_notes_tab_log'
  | 'mca_notes_tab_landlord_qa'
  | 'mca_notes_tab_merchant_qa'
  | 'mca_notes_tab_merchant_qa_new'
  | 'mca_notes_tab_merchant_qa_es'
  | 'mca_notes_tab_collection_report_qa'
  | 'mca_notes_tab_funding_call_r'
  | 'mca_notes_tab_funding_call_w'
  | 'mca_creditcard_r'
  | 'mca_creditcard_w'
  | 'mca_collection_r'
  | 'mca_collection_w'
  | 'mca_ar_w'
  | 'mca_ar_assign'
  | 'mca_settlement_user_w'
  | 'mca_next_payment_w'
  | 'underwriting_funding_review_approve'
  | 'underwriting_funding_review_decline'
  | 'underwriting_funding_review_modify'
  | 'underwriting_funding_review_note'
  | 'menu_transactions'
  | 'menu_transactions_advanced'
  | 'menu_ref_data'
  | 'menu_ref_users'
  | 'menu_ref_ar'
  | 'menu_lender'
  | 'menu_pm'
  | 'menu_collateral_provider'
  | 'menu_pm_collateral_base_point'
  | 'menu_mca_wf_dashboard'
  | 'menu_collection'
  | 'menu_accounting_dashboard'
  | 'menu_mca'
  | 'menu_loan'
  | 'menu_loan_new'
  | 'menu_loan_release'
  | 'menu_external_cpa'
  | 'menu_commission_invoice'
  | 'menu_aggregation'
  | 'menu_quickbooks_reports'
  | 'menu_funding_review'
  | 'menu_pm_summary'
  | 'menu_pm_mcatransactionsummary'
  | 'menu_pm_refinancecandidates'
  | 'menu_pm_accounting'
  | 'menu_pm_dealstatuschange'
  | 'menu_pm_portfolio'
  | 'menu_pm_portfolio_analytics'
  | 'menu_pm_receivableach'
  | 'menu_pm_collateralportfolio'
  | 'menu_pm_collateralized_provider_view'
  | 'menu_pm_investortransprojection'
  | 'menu_pm_portfolio_overview'
  | 'menu_pm_time_in_status'
  | 'menu_pm_completed_deals'
  | 'menu_pm_funded_in_range'
  | 'menu_pm_payment_curve'
  | 'menu_pm_transfer_account'
  | 'menu_pm_email_reports'
  | 'menu_pm_pre_approved_offers'
  | 'menu_pm_collection_assignment_change'
  | 'mca_data_full_view'
  | 'menu_isorelation'
  | 'menu_iso_active_offers'
  | 'menu_mca_new'
  | 'menu_mca_assignments'
  | 'menu_workload'
  | 'menu_loan_transactions'
  | 'set_payment_reminder'
  | 'system_cangelog_view'
  | 'user_financial_bankinfo'
  | 'user_financial_settings'
  | 'user_edit_financial_commissions'
  | 'user_edit_financial_fees'
  | 'user_edit_financial_strategies'
  | 'user_edit_financial_investment_specials'
  | 'user_edit_docs'
  | 'menu_pm_docs'
  | 'pm_doc_w'
  | 'reactivate_transaction_request'
  | 'cc_charge'
  | 'collection_acct_recons_r'
  | 'collection_acct_recons_w'
  | 'acct_recons_w_start_date'
  | 'collection_outstanding_tasks'
  | 'collection_review_report'
  | 'coll_agent_stat_drilldown'
  | 'portfolio_analytics_w'
  | 'ocrolus_r'
  | 'ocrolus_w'
  | 'collateral_base_point_drilldown'
  | 'collection_base_point_delinquent'
  | 'agents_kpi'
  | 'agents_kpi_drilldown';
